export function init (env) {
  const { app } = env;
  app.$services.register('crunch:app-config', app.config);

  app.routing.initialize();

  const hammer = env.hammer = new app.Hammer();

  // Expose the Vue router to the app and services
  app.router = hammer.router;
}

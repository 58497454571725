<template>
  <simple-layout>
    <h1>{{ $t('title') }}</h1>
  </simple-layout>
</template>

<script>

import SimpleLayout from '../../../shared/components/SimpleLayout';
export default {
  name: 'NotFoundPage',
  components: { SimpleLayout },
  i18nOptions: {
    keyPrefix: 'components.not-found'
  }
};
</script>

<style scoped>

</style>

<template>
  <b-container
    fluid
    class="tile-view-container"
  >
    <folder-empty v-if="folderIsEmpty" />
    <b-row
      v-else
      align-h="start"
      class="tile-view-container__row"
    >
      <b-col
        v-for="(folder, i) in data.folders"
        :key="`folder-${i}`"
        class="tile-view-container__col"
        col
        :sm="3"
        :lg="2"
        :xl="1"
      >
        <folder
          :model="folder"
        />
      </b-col>
      <b-col
        v-for="(file, i) in data.files"
        :key="`file-${i}`"
        class="tile-view-container__col"
        col
        :sm="3"
        :lg="2"
        :xl="1"
      >
        <file-tile
          :model="file"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Folder from 'modules/gallery/components/Folder';
import FileTile from 'modules/gallery/components/FileTile';
import FolderEmpty from 'modules/gallery/components/FolderEmpty';

export default {
  name: 'TileViewContainer',
  props: {
    data: {
      type: Object,
      required: true
    },
    folderIsEmpty: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Folder,
    FileTile,
    FolderEmpty
  }
};
</script>

<style lang="scss" scoped>
.tile-view-container {
  padding-left: 0;
  padding-right: 0;
  margin-top: 41px;

  &__row {
    margin-left: 0;
    margin-right: 0;
  }
  &__col {
    padding-left: 2px;
    padding-right: 2px;
    margin-bottom: 37px;
    display: flex;
    justify-content: center;
  }
}
</style>

export function makeSisFile ({
  path,
  chost,
  token,
  mode,
  viewingmode,
  x = 0,
  y = 0,
  width = 0,
  height = 0,
  zoom = 0,
  focus = 0
}) {
  const host = chost || window.location.hostname;
  x = width / 2 - width * x;
  y = height / 2 - height * y;
  let url = `//${host}`;
  if (token) {
    url += `/@@${token}`;
  }
  url += path;

  return `<SIS version="1.0">
  ${viewingmode ? '<ViewingMode>1</ViewingMode>' : ''}
  ${viewingmode ? `<Mode>${mode}</Mode>` : ''}
  <Image>
    <URL>${url}</URL>
    ${x ? `<X>${x}</X>` : ''}
    ${y ? `<Y>${y}</Y>` : ''}
    ${zoom ? `<Zoom>${zoom}</Zoom>` : ''}
    ${focus ? `<Focus>${focus}</Focus>` : ''}
  </Image>
</SIS>
`;
}

import { enumeration } from './enum';

export function makeError (code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}

export const ERROR_CODES = enumeration({
  // License errors
  UNLICENSED_SYSTEM: 1001,
  LICENSE_EXPIRED: 1002,
  // Permission errors
  PERMISSION_DENIED: 4010,
  // Other errors
  UNHANDLED_EXCEPTION: 9999
});

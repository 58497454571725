import SisFileContainer from './SisFileContainer';

export function initImageScopeLauncher (app) {
  app.routing.addRoutes({
    path: '/imagescope/*',
    name: 'launch-imagescope',
    component: {
      components: { SisFileContainer },
      template: '<sis-file-container/>',
      beforeRouteEnter ({ params }, from, next) {
        /**
         * The `beforeRouteEnter` guard does NOT have access to `this` component instance
         * access to component instance via `vm`
         */
        next(vm => {
          vm.$store.commit('updatePath', params.pathMatch);
        });
      },
      beforeRouteUpdate ({ params }, from, next) {
        this.$store.commit('updatePath', params.pathMatch);
        next();
      }
    }
  });
}

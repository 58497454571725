<template>
  <span :class="`brand-theme-${theme}`">
    <span class="brand">{{ $t('gallery-main:brand-name') }}</span>&nbsp;
    <span class="product">{{ $t('gallery-main:application-name') }}</span>
  </span>
</template>

<script>
export default {
  name: 'ProductName',

  props: {
    theme: {
      type: String,
      default: 'light'
    }
  }
};
</script>

<style scoped
       lang="scss">
@import "~lbs-pi-web-styles/lib/variables";

.brand-theme-dark {
  .brand {
    color: $leica-warm-grey-light-dark;
  }

  .product {
    color: $leica-white;
  }
}

.brand-theme-light {
  .brand {
    color: $leica-grey-light;
  }

  .product {
    color: $leica-grey-medium;
  }
}
</style>

<template>
  <b-row class="folder-empty">
    <b-col
      cols="12"
      class="d-flex justify-content-center align-items-center flex-column"
    >
      <img
        class="folder-empty__img"
        src="/static/images/folder-empty-inside.svg"
        :alt="$t('alt-folder-empty-inside')"
      >
      <p class="folder-empty__title">
        {{ $t('folder-empty') }}
      </p>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'FolderEmpty',
  i18nOptions: {
    keyPrefix: 'modules.gallery'
  }
};
</script>

<style lang="scss" scoped>
@import '~lbs-pi-web-styles/lib/_variables';

.folder-empty {
  padding-top: 25vh;

  &__img {
    width: 40px;
    height: 32px;
    margin-bottom: 24px;
  }
  &__title {
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 0.875rem;
    text-align: center;
    color: $leica-black;
    opacity: 0.5;
    margin: 0;
  }
}
</style>

import { makeActions } from 'shared/store/actions';
import { makeMutations } from 'shared/store/mutations';
import { makeGetters } from 'shared/store/getters';
import { makeState } from 'shared/store/state';

export function makeStore (api) {
  return {
    namespaced: true,
    state: makeState(),
    actions: makeActions(api),
    mutations: makeMutations(),
    getters: makeGetters()
  };
}

import { addConfig } from './config';
import { addEvents } from './events';
import { addCache } from './cache';
import { configureI18n } from './i18n';

export async function createAppFoundation (Hammer) {
  const app = {};

  app.Hammer = Hammer;
  app.Vue = Hammer.Vue;
  app.$services = Hammer.$services;

  Hammer.config.router.options.mode = 'history';
  addConfig(app);
  addEvents(app);
  addCache(app);
  // TODO: Figure out how to configure i18n without need for await/promises (it downloads the i18n file and complains
  //  loudly if it's not there when you try to use it)
  await configureI18n(app);

  return app;
}

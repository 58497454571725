import { createCoreServices } from './services';
import { createAppFoundation } from './infrastructure';
import { addLifecycleMethods } from './lifecycle/index';

export async function makeApp (Hammer) {
  const app = await createAppFoundation(Hammer);
  createCoreServices(app);
  addLifecycleMethods(app);

  return app;
}

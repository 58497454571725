<template>
  <b-breadcrumb v-if="items.length">
    <b-breadcrumb-item
      v-for="(item, index) of items"
      :to="item.href"
      :active="index === items.length - 1"
      :key="index"
    >
      {{ item.label }}
    </b-breadcrumb-item>
  </b-breadcrumb>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    items: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss">
.breadcrumb {
  margin-bottom: 24px;
  padding: 0;
  background: none;

  .breadcrumb-item {
    a {
      font-family: Roboto Condensed, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #33A3DC;
    }
    &.active {
      a {
        color: #464652;
      }
    }
    &:not(:first-child) {
      &:before {
        content: url('/static/images/arrow-breadcrumb.svg');
      }
    }
  }
}
</style>

<template>
  <div class="simple-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SimpleLayout'
};
</script>

<style scoped lang="scss">
@import '~lbs-pi-web-styles/lib/_variables';
@import '../styles/app-variables';

.simple-container {
  padding: 24px 29px;
  max-width: 1264px;
  min-height: 100vh;
  margin: 40px auto 0;
  background: $leica-white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}
</style>

import { clone } from 'shared/util/clone';

export const DEFAULT_STATE = Object.freeze({
  loading: false,
  path: '/',
  files: [],
  folders: []
});

export function makeState () {
  return clone(DEFAULT_STATE);
}

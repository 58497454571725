import RouterPassthrough from 'shared/components/RouterPassthrough';
import Startup from '../../../modules/startup/Startup';
import ErrorReport from '../../../modules/startup/ErrorReport';
import { LIFECYCLE_STEPS } from '../../lifecycle/consts';
import NotFoundPage from './NotFoundPage';

export const DEFAULT_ROUTE = '/gallery';

export function initRouting (app) {
  const childRoutes = [];
  const baseRoutes = [
    {
      path: '/',
      redirect: { name: 'galleryRoot' },
      component: RouterPassthrough,

      meta: {
        noauth: true
      },

      children: childRoutes
    },

    // Startup error route
    {
      path: '/startup/error',
      name: 'crunch:startup:error',
      component: ErrorReport,

      meta: {
        startupExempt: true
      }
    },

    // Startup route. Display as app is initializing
    {
      path: '/startup/:returnTo?',
      name: 'crunch:startup',
      component: Startup,

      meta: {
        startupExempt: true
      }
    },

    // fallback any undefined routes to root which will handle additional redirect logic
    {
      path: '*',
      component: NotFoundPage
    }
  ];

  app.routing = {
    // Allow creation of "app startup status aware" route guards.
    beforeEach (fn) {
      app.Hammer.router.beforeEach((to, from, next) => {
        const appVm = app.$services.resolve('crunch:app-vm');
        if (appVm.status === LIFECYCLE_STEPS.RUNNING) {
          fn(to, from, next);
        } else if (to.meta && to.meta.doNotRedirectOnStartup) {
          // "doNotRedirectOnStartup" is reserved for error handling routes. If the user refreshes the page we don't
          // want to keep showing them an "error" page without at least trying again.
          next({ name: 'crunch:startup' });
        } else if (!to.meta || !to.meta.startupExempt) {
          next({ name: 'crunch:startup', params: { returnTo: to.path } });
        } else {
          next();
        }
      });
    },

    // Exposed to allow modules to create child routes
    addRoutes (...routes) {
      childRoutes.push(...routes);
    },

    // Exposed to configure Vue Router (via Hammer)
    initialize () {
      app.Hammer.router.configure({
        base: process.env.BASE_URL
      });
      app.Hammer.router.addRoutes(baseRoutes);
    }
  };
}

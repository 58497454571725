import { hasOwnProperty } from 'shared/util/js-language';

export function enumeration (obj, ...keys) {
  // If the enum has defined values make both keys and values accessible (only keys should be iterate-able):
  if (typeof obj === 'object') {
    const e = Object.keys(obj).reduce((accumulator, key) => {
      const val = obj[key];
      Object.defineProperties(accumulator, {
        [key]: {
          value: val,
          writable: false,
          enumerable: true
        },
        [val]: {
          value: key,
          writable: false,
          enumerable: false
        }
      });
      return accumulator;
    }, {});
    return Object.freeze(e);
  }

  // If we just get a list of strings just make them equal
  keys.unshift(obj);
  const enm = keys.reduce((e, key) => {
    if (hasOwnProperty(e, key)) {
      throw new Error(`Found duplicate key "${key}". Enum values must be unique.`);
    }
    e[key] = key;
    return e;
  }, {});
  return Object.freeze(enm);
}

export function iterableEnum (...keys) {
  const enm = keys.reduce((e, key, index) => {
    if (hasOwnProperty(e, key)) {
      throw new Error(`Found duplicate key "${key}". Enum values must be unique.`);
    }
    Object.defineProperties(e, {
      [key]: {
        value: index,
        writable: false,
        enumerable: true
      },
      [index]: {
        value: key,
        writable: false,
        enumerable: false
      }
    });
    return e;
  }, {});
  enm.length = keys.length;
  return Object.freeze(enm);
}

export function addEvents (app) {
  const eventChannel = new app.Vue();
  const { $on, $once, $off, $emit } = eventChannel;

  // TODO: ensure trigger is async so app.start() can be `await`ed

  Object.assign(app, {
    on: $on.bind(eventChannel),
    once: $once.bind(eventChannel),
    off: $off.bind(eventChannel),
    trigger: $emit.bind(eventChannel)
  });
}

<template>
  <b-tr class="file-container">
    <b-td>
      <router-link
        :to="{ path: webViewerLink }"
        append
      >
        <div class="d-flex align-items-center file-container__b-name">
          <b-overlay
            :show="thumbnailImgLoading"
            rounded="sm"
            class="file-container__b-name-overlay d-flex align-items-center"
          >
            <template #overlay>
              <indeterminate-progress-bar anchored />
            </template>
            <img
              :src="model.thumbnailUrl"
              :alt="$t('alt-icon-file')"
              @load="thumbnailImgLoading = false"
              v-show="!thumbnailImgLoading"
            >
          </b-overlay>
          <span>{{ model.name }}</span>
        </div>
      </router-link>
    </b-td>
    <b-td class="file-container__viewers">
      <router-link
        :to="webViewerLink"
        tag="p"
      >
        {{ $t('web-viewer') }}
      </router-link>
      <router-link
        :to="imageScopeLink"
        tag="p"
      >
        {{ $t('image-scope') }}
      </router-link>
    </b-td>
    <b-td class="file-container__dimension">
      {{ parseDesc.dimensions }}
    </b-td>
    <b-td class="file-container__label">
      <b-overlay
        :show="labelImgLoading"
        rounded="sm"
        class="file-container__label-overlay d-flex align-items-center"
      >
        <template #overlay>
          <indeterminate-progress-bar anchored />
        </template>
        <img
          :src="model.labelUrl"
          :alt="$t('alt-icon-file-label')"
          @load="labelImgLoading = false"
          v-show="!labelImgLoading"
        >
      </b-overlay>
    </b-td>
    <b-td class="file-container__macro">
      <b-overlay
        :show="macroImgLoading"
        rounded="sm"
        class="file-container__macro-overlay d-flex align-items-center"
      >
        <template #overlay>
          <indeterminate-progress-bar anchored />
        </template>
        <img
          :src="model.macroUrl"
          :alt="$t('alt-icon-file-macro')"
          @load="macroImgLoading = false"
          v-show="!macroImgLoading"
        >
      </b-overlay>
    </b-td>
    <b-td class="file-container__title">
      {{ model.title }}
    </b-td>
    <b-td class="file-container__attributes">
      {{ model.date }}
    </b-td>
    <b-td class="file-container__attributes">
      {{ model.time }}
    </b-td>
    <b-td class="file-container__attributes">
      {{ parseDesc.quality }}
    </b-td>
    <b-td class="file-container__attributes">
      {{ fileSize }}
    </b-td>
  </b-tr>
</template>

<script>
import IndeterminateProgressBar from 'shared/components/IndeterminateProgressBar';

import { readableBytes } from 'shared/util/readableBytes';
import { parseTableAttributes } from 'shared/util/parseTableAttributes';
import { buildPureUrlViewer } from 'shared/util/buildPureUrlViewer';
import { buildPureUrlImageScope } from 'shared/util/buildPureUrlImageScope';

export default {
  name: 'File',

  i18nOptions: {
    keyPrefix: 'modules.gallery'
  },

  props: {
    model: {
      type: Object,
      required: true
    }
  },
  components: {
    IndeterminateProgressBar
  },
  watch: {
    'model.thumbnailUrl': function () {
      this.thumbnailImgLoading = true;
    },
    'model.labelUrl': function () {
      this.labelImgLoading = true;
    },
    'model.macrolUrl': function () {
      this.macroImgLoading = true;
    }
  },
  data () {
    return {
      thumbnailImgLoading: true,
      labelImgLoading: true,
      macroImgLoading: true
    };
  },
  computed: {
    fileSize () {
      return readableBytes(this.model.size);
    },
    parseDesc () {
      return parseTableAttributes(this.model.description);
    },
    webViewerLink () {
      return buildPureUrlViewer(this.model?.thumbnailUrl);
    },
    imageScopeLink () {
      return buildPureUrlImageScope(this.model?.thumbnailUrl);
    }
  }
};
</script>

<style lang="scss">
@import '~lbs-pi-web-styles/lib/_variables';

.file-container {
  td {
    text-align: left;
    vertical-align: middle;
    padding: 6px 12px;
  }

  &__b-name {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.188rem;
    color: $leica-warm-grey-dark;
    word-break: break-all;

    &-overlay {
      min-width: 80px;
      min-height: 60px;
      margin-right: 16px;
      display: flex;
      align-items: center;

      .b-overlay {
        width: 100%;     height: 100%;

        div {
          width: 100%;
          height: 100%;
        }
      }
    }

    & img {
      max-width: 100%;
      width: auto;
      height: 60px;
    }
  }
  &__viewers {
    p {
      font-family: 'Roboto Condensed', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.188rem;
      color: $leica-blue;
      margin-right: 16px;
      margin-bottom: 0;
      cursor: pointer;
      display: inline-block;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__dimension {
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $leica-warm-grey-dark;
  }
  &__label {
    &-overlay {
      width: 60px;
      min-height: 60px;

      .b-overlay {
        width: 100%;
        height: 100%;

        div {
          width: 100%;
          height: 100%;
        }
      }
    }
    img {
      max-width: 100%;
      max-height: 60px;
      width: auto;
      height: auto;
    }
  }
  &__macro {
    &-overlay {
      width: 106px;
      min-height: 60px;

      .b-overlay {
        width: 100%;
        height: 100%;

        div {
          width: 100%;
          height: 100%;
        }
      }
    }
    img {
      max-width: 100%;
      max-height: 60px;
      width: auto;
      height: auto;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: $leica-black;
  }
  &__attributes {
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $leica-warm-grey-dark;
  }
}
</style>

<template>
  <div
    class="right-slider"
    :class="{'is-visible': show }"
  >
    <div
      v-if="show"
      class="wrapper"
    >
      <help />
      <system-info />
    </div>
  </div>
</template>

<script>
import Help from './Help';
import SystemInfo from './SystemInfo';
export default {
  name: 'InfoSlider',
  components: { SystemInfo, Help },
  props: {
    show: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
@import "~lbs-pi-web-styles/lib/variables";
@import "../../styles/app-variables";

.right-slider {
  position: fixed;
  top: $site-header-height;
  right: 0;
  height: calc(100vh - #{$site-header-height});
  width: calc(100vw - 50px);
  border: 1px solid black;
  box-shadow: -10px -4px 10px -5px rgba(0, 0, 0, 0.75);
  background: $leica-white;
  opacity: 0.95;
  transform: translate(100vw, 0);
  transition: transform 500ms ease;

  &.is-visible {
    transform: translate(0, 0);
  }
}

.wrapper {
  display: grid;
  grid-template-columns: auto 250px;
  column-gap: $site-padding;
  height: 100%;
  width: 100%;
  padding: $site-padding;
}
</style>

import Vue from 'vue';
import Vuex from 'vuex';

export async function mount ({ app, hammer, AppContainer, store }) {
  Vue.use(Vuex);

  store = new Vuex.Store(store);

  app.$root = await hammer.startVue({
    el: '#app',
    store,
    render: h => h(AppContainer)
  });
}

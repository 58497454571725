export function makeActions (api) {
  return {
    async getGalleryItems ({ commit, state }, query) {
      let files = [];

      commit('updateLoading', true);

      try {
        const { data } = await api.getGalleryItems(query);

        if (Array.isArray(data?.files)) {
          files = data.files.map((file) => {
            const path = `${state.path}/${file.name}`.replace(/^\/|\/$/g, '');
            const urlsData = api.getImageUrls({ path });

            return {
              ...file,
              thumbnailUrl: urlsData.thumbnailUrl,
              labelUrl: urlsData.labelUrl,
              macroUrl: urlsData.macroUrl
            };
          });
        }

        commit('updateFiles', files);
        commit('updateFolders', data.folders);
      } catch (e) {
        query = query.replace('/view.apml', '');
        window.location.href = `/view/${query}`;
      }

      commit('updateLoading', false);
    }
  };
}

<template>
  <div class="info-container">
    <div class="product-info">
      <h6>{{ $t('product-info-label') }}</h6>
      <dl>
        <dt>{{ $t('name-label') }}</dt>
        <dd><product-name /></dd>

        <dt>{{ $t('udi-label') }}</dt>
        <dd>{{ $t('udi-value', configVm) }}</dd>

        <dt>{{ $t('updated-label') }}</dt>
        <dd>{{ $t('updated-value', configVm) }}</dd>
      </dl>
    </div>

    <div class="support-info">
      <h6>{{ $t('support-label') }}</h6>
      <dl>
        <template v-for="(key, index) in ['phone1', 'phone2', 'email']">
          <dt :key="index">
            {{ $t(`${key}-label`) }}
          </dt>
          <dd :key="key">
            {{ $t(`${key}-value`) }}
          </dd>
        </template>
      </dl>
    </div>

    <div class="copyright">
      {{ $t('copyright') }}
    </div>
  </div>
</template>

<script>
import ProductName from '../ProductName';
export default {
  name: 'SystemInfo',
  components: { ProductName },
  i18nOptions: {
    keyPrefix: 'components.information-panel'
  },

  inject: ['configVm']
};
</script>

<style scoped lang="scss">
@import "~lbs-pi-web-styles/lib/variables";

.info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

dl {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid $leica-grey-light;
  border-top: 1px solid $leica-grey-light;
}

dt,
dd {
  border-top: 1px solid $leica-grey-light;
  margin: 0;
  line-height: 2.5rem;
  white-space: nowrap;
}

dd {
  padding-left: 5px;
}
</style>

// https://en.wikipedia.org/wiki/Chain-of-responsibility_pattern

import { enumeration } from './enum';

export const CHAIN = enumeration({
  STOP: true,
  CONTINUE: false
});

export function chain (impl) {
  let next;

  const self = {
    setNext (val) {
      next = val;
      next.parent = self;
      return next;
    },

    async handle (...args) {
      const result = await impl(...args);
      if (next && result !== CHAIN.STOP) {
        return next.handle(...args);
      }
      return result;
    },

    // Syntactic sugar for creating chains. Returns the root node.
    finish () {
      let root = self;
      while (root.parent) {
        root = root.parent;
      }
      return root;
    }
  };

  return self;
}

import { DEFAULT_ROUTE } from '../services/routing';

export function restoreAppState ({ app }) {
  // This transfers control from the app router to the Vue router

  // The startup route blocks submodule code from executing until the application is fully started.
  if (app.router.currentRoute.name === 'crunch:startup') {
    const next = app.router.currentRoute.params.returnTo || DEFAULT_ROUTE;
    app.router.push(next);
  }
}

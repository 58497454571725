/**
 * @param desc
 * @returns {any}
 */
export function parseTableAttributes (desc) {
  const outputObj = {
    dimensions: '',
    quality: '',
    imageType: '',
    ratio: ''
  };

  if (!desc || typeof desc !== 'string') return outputObj;

  const arrOfDesc = desc.split(' ');

  outputObj.dimensions = arrOfDesc[arrOfDesc.length - 4].replace(']', '').replace('x', ' x ');
  outputObj.quality = arrOfDesc[arrOfDesc.length - 1].replace(/\D/g, '');
  outputObj.imageType = arrOfDesc[arrOfDesc.length - 2];
  outputObj.ratio = arrOfDesc[arrOfDesc.length - 3];

  return outputObj;
}

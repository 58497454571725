import { DEFAULT_STATE } from 'shared/store/state';

export function makeMutations () {
  return {
    updateFiles (state, files) {
      state.files = files;
    },
    updateFolders (state, folders) {
      state.folders = folders;
    },
    updatePath (state, path) {
      state.path = path ? `/${path}` : DEFAULT_STATE.path;
    },
    updateLoading (state, loading) {
      state.loading = loading;
    }
  };
}

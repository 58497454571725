const CACHE_KEY = 'crunch:global-cache';

export const CACHE_PERMANENTLY = true;

export function addCache (app) {
  function add (key, fn, doNotClear) {
    return async function (...args) {
      let item = restore(key);
      if (!item) {
        item = await fn.apply(this, args);
        set(key, item, doNotClear);
      }
      return item;
    };
  }

  function set (key, item, doNotClear = false) {
    save(key, item);
    if (!doNotClear) {
      rememberKey(key);
    }
  }

  // Saves a list of keys this cache is tracking so that it can be emptied.
  function rememberKey (newKey) {
    const keys = restore(CACHE_KEY, []);
    keys.push(newKey);
    save(CACHE_KEY, keys);
  }

  // Allows us to empty all keys this cache knows about
  function clear () {
    const keys = restore(CACHE_KEY, []);
    keys.forEach((i) => { localStorage.removeItem(i); });
    localStorage.removeItem(CACHE_KEY);
  }

  // When the user logs out, let's empty the cache completely
  app.on('before-logout', clear);

  // Expose the cache mechanism to the app
  app.cache = {
    add,
    set,
    get: restore,
    clear
  };
}

function restore (key, defaultValue = null) {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : defaultValue;
}

function save (key, item) {
  localStorage.setItem(key, JSON.stringify(item));
}

<template>
  <div
    class="loading-animation"
    :class="{ anchored: anchored }"
  >
    <span
      v-for="n in 6"
      :key="n"
    />
  </div>
</template>

<script>
export default {
  name: 'IndeterminateProgressBar',
  props: {
    anchored: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '~lbs-pi-web-styles/lib/variables';

  /* Create a mixin to programmatically assign a numbered animation keyframe to each child in the progress bar */
  @mixin assignNumberedAnimation {
    @for $i from 1 to 10 {
      &:nth-child(#{$i}) {
        animation-name: pulse+$i;
      }
    }
  }

  /* Create a mixin of keyframe percentages so that all other keyframe definitions can assume each 10th percent is set to zero opacity */
  @mixin keyframeOpacity {
    0% { opacity: 0; }
    10% { opacity: 0; }
    20% { opacity: 0; }
    30% { opacity: 0; }
    40% { opacity: 0; }
    50% { opacity: 0; }
    60% { opacity: 0; }
    70% { opacity: 0; }
    80% { opacity: 0; }
    90% { opacity: 0; }
    100% { opacity: 0; }
  }

  @keyframes pulse1 {
    @include keyframeOpacity;
    10% { opacity: 1; }
    20% { opacity: 0.5; }
  }

  @keyframes pulse2 {
    @include keyframeOpacity;
    0% { opacity: 0.5; }
    20% { opacity: 1; }
    30% { opacity: 0.5; }
    100% { opacity: 1; }
  }

  @keyframes pulse3 {
    @include keyframeOpacity;
    30% { opacity: 1; }
    40% { opacity: 0.5; }
    90% { opacity: 1; }
    100% { opacity: 0.5; }
  }

  @keyframes pulse4 {
    @include keyframeOpacity;
    40% { opacity: 1; }
    50% { opacity: 0.5; }
    80% { opacity: 1; }
    90% { opacity: 0.5; }
  }

  @keyframes pulse5 {
    @include keyframeOpacity;
    50% { opacity: 1; }
    60% { opacity: 0.5; }
    70% { opacity: 1; }
    80% { opacity: 0.5; }
  }

  @keyframes pulse6 {
    @include keyframeOpacity;
    60% { opacity: 1; }
    70% { opacity: 0.5; }
  }

  .loading-animation {
    list-style: none;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;

    &.anchored {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 5px;
      background-color: $leica-warm-grey-dark;
      border-radius: 0;
    }
    &:not(.anchored) {
      width: 240px;
      height: 10px;
      background-color: $leica-warm-grey-light;
      border-radius: 5px;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
  }

  .loading-animation span {
    opacity: 0;
    width: calc((100% - (5px * 5) ) / 6);
    height: 10px;
    display: list-item;
    float: left;
    margin-right: 5px;
    background-color: $leica-blue;
    animation: 2.5s infinite linear;
    animation-delay: 0s;
    transition: opacity 2s linear;

    @include assignNumberedAnimation;
  }

  .loading-animation span:last-child {
    float: right;
    margin: 0;
  }
</style>

<template>
  <div>
    <iframe
      :src="helpSource"
      :title="$t('help-iframe-title')"
    />
  </div>
</template>

<script>
export default {
  name: 'Help',

  computed: {
    helpSource () {
      return '/static/help/index.html';
    }
  }
};
</script>

<style scoped
       lang="scss">
iframe {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
}
</style>

const config = Object.freeze({
  internalVersion: process.env.VUE_APP_VERSION,
  udi: process.env.VUE_APP_UDI,
  buildDate: new Date(process.env.VUE_APP_BUILD_DATE),
  serverUrl: window.location.origin,
  authPath: process.env.VUE_APP_AUTH_SERVER_PATH
});

export function addConfig (app) {
  Object.defineProperty(app, 'config', {
    get () {
      return config;
    }
  });
}
